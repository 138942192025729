<template>
  <v-container fluid>
    <v-container fluid>
      <v-card color="white">
        <v-layout row>
          <v-card flat>
            <v-badge left overlap>
              <template v-slot:badge>
                <v-btn
                  round
                  color="primary"
                  fab
                  small
                  style="margin-top: -1.5em;"
                  class="elevation-0"
                  v-if="pagination.totalItems"
                  >{{ pagination.totalItems }}</v-btn
                >
              </template>

              <v-card-text class="title">Admit Card By Subject</v-card-text>
            </v-badge>
          </v-card>
          <v-divider class="my-2" vertical></v-divider>
        </v-layout>
      </v-card>

      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="getCuAdmitCard"
        :total-items="pagination.totalItems"
        :pagination.sync="pagination"
        :rows-per-page-items="pagination.rowsPerPageItems"
      >
        <template v-slot:items="props">
          <td>{{ props.index + 1 }}</td>
          <td class="text-xs" style="padding: 0 0px; !important">
            <v-chip color="transparent"
              >{{ props.item.exam_code }}- {{ props.item.exam_name }}</v-chip
            >
          </td>
          <td class="text-xs-left">
              {{ props.item.subject_code }}-
              {{ props.item.subject_name }}
          </td>

          <!-- <td class="text-xs">{{ props.item.session }}</td> -->
          <td class="text-xs">{{ props.item.year }}</td>
          <td class="text-xs-center">{{ props.item.student_count }}</td>
          <td class="justify-center text-xs-center">
            <v-layout row>
              <v-btn small @click="downloadAdmit(props.item)">Admit card</v-btn>
              <!-- <template v-for="item2 in getIndividualAdmitCardDownload">
                <template v-for="item3 in item2">
                  <v-btn small @click="downloadIndividualAdmit(item3.admit_card_url)" v-if="Number(props.item.subject_code) == Number(item3.subject_code)">Admit card - {{ item3.reg_no }}</v-btn>
                </template>               
              </template>            -->
            </v-layout>
          </td>
          
        </template>
      </v-data-table>
    </v-container>
    <!-- Special Admit Card Download -->
    <v-container fluid>
      <v-card color="white">
        <v-layout row>
          <v-card
            flat
            style="padding-left:10px"
            v-if="getIndividualAdmitCardDownload.length"
          >
            <br />
            <h3 style="padding-left:10px">Individual Admit Card </h3>
            <hr>
            <!-- <br /> -->
            <v-card-text>
              <table class="table text-center">
                <tbody>
                  <tr
                    v-for="(item, index) in getIndividualAdmitCardDownload"
                    :key="index"
                  >
                    <td  v-for="(item2, index2) in item" :key="index2+'-'+index" style="background-color: white;">
                      <v-flex md4 >
                        <div class="text-xs-center" > 
                          <v-btn
                            round
                            color="primary"
                            medium
                            dark
                            class="w-250"
                            @click="downloadIndividualAdmit(item2.admit_card_url)"
                            style="font-size:12px;background-color: white;"
                          >
                            Admit Card - {{ item2.reg_no }}
                            <v-icon right dark>cloud_download</v-icon>
                          </v-btn>
                        </div>
                      </v-flex>
                    </td>

                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-card>
        </v-layout>
      </v-card>
    </v-container>
    <!-- close Special Admit Admit Card  -->
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      pagination: {
        rowsPerPage: 10,
        rowsPerPageItems: [1, 5, 10, 25, { text: "All", value: -1 }]
      },
      sl_count: 0,
      loading: false,
      expand: false,
      headers: [
        { text: "SL.", align: "left", value: "id", sortable: false },
        { text: "Exam", value: "exam_code", sortable: false },
        { text: "Subject", value: "subject_code", sortable: false },
        { text: "Year", value: "year", sortable: false },
        {
          text: "Student Count",
          align: "center",
          value: "student_count",
          sortable: false
        },
        { text: "Download", align: "center", value: "view", sortable: false }
      ],
      information:{
        exam_initiate_id: null,
        exam_year: null,
        exam_code: null,
        college_code: null,
        year: null,
        subject_code: null,
        degree_code:null,
        degree_group_code:null
      }
    };
  },
  created() {
    // console.log('information',this.information);
    this.setInformation();
    this.$store.dispatch("getCuAdmitCard",this.information);
    this.fetchIndividualAdmitCard(); 
  },
  computed: {
    college() {
      return this.$store.getters.getAuthCollege;
    },
    getCuAdmitCard() {
      return this.$store.getters.getCuAdmitCard;
    },
    getIndividualAdmitCardDownload(){
      return this.$store.getters.getIndividualAdmitCardDownload;
    }
  },
  methods: {
    setLimit() {
      const { sortBy, descending, page, rowsPerPage } = this.pagination;
      this.searchParam.page = page;
      this.searchParam.limit = rowsPerPage;
      this.searchParam.sortBy = sortBy;
      this.searchParam.search = this.search;
    },
    fetchIndividualAdmitCard(){
      this.setInformation();
      this.$store.dispatch("fetchCollegeDeptIndividualSpecialAdmitCard", this.information)
    },
    setInformation(){
      let currentPriviledge = JSON.parse(
        localStorage.getItem("current-privilege")
      );
      let collegeUser =  JSON.parse(
        localStorage.getItem("college-user")
      );
      // console.log('current-priviledge',currentPriviledge)
      this.information.exam_initiate_id = currentPriviledge.exam_initiate_id;
      this.information.exam_year = currentPriviledge.exam_master.exam_year;
      this.information.exam_code = currentPriviledge.exam_master.exam_code;
      this.information.college_code = collegeUser.college_code;
      this.information.year = currentPriviledge.exam_master.exam_year;
      this.information.subject_code = currentPriviledge.subject_code;
      this.information.degree_code = currentPriviledge.degree_code;
      this.information.degree_group_code = currentPriviledge.degree_group_code;
    },
    downloadIndividualAdmit(url){
      var fileLink = document.createElement("a");
        fileLink.href = url;
        // fileLink.setAttribute("download", file_name);
        fileLink.setAttribute("target", "_blank");
        document.body.appendChild(fileLink);
        fileLink.click();
    },
    fetchAdmitCardDataBySubject() {
      this.loading = true;
      this.setLimit();
      this.$store
        .dispatch("fetchAdmitCardDataBySubject", this.searchParam)
        .then(data => {
          this.loading = false;
          this.sl_count = data.from;
          this.pagination.totalItems = data.total;
        });
    },
    downloadAdmit(item) {
      var param = {
        exam_code: item.exam_code,
        subject_code: item.subject_code,
        exam_year: item.year,
        college_code: item.college_code,
        module_name: "College"
      };

      this.$store.dispatch("downloadAdmitCollegewise", param).then(result => {
        result.forEach(url => {
          setTimeout(function() {
            var fileLink = document.createElement("a");
            fileLink.href = url;
            fileLink.setAttribute("download", "file.pdf");
            fileLink.setAttribute("target", "_blank");
            document.body.appendChild(fileLink);
            fileLink.click();
          }, 1000);
        });
      });
    }
  },
  watch: {
    pagination() {
      this.fetchAdmitCardDataBySubject();
    },
  },
  mounted() {
  }
};
</script>